<template>
  <div class="pa-5">
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'Measures.MeasuresManagement'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click="$router.push('Measures/new')">
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('Measures.AddMeasure') }}
        </v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th v-t="'General.PublishingStatus'" class='text-center'></th>
            <th v-t="'Measures.MeasureName'" class='text-center'></th>
            <th v-t="'General.Actions'" class='text-center'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='measure in measures' :key='measure.id'>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyMeasure(measure)"
                    v-model="measure.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ measure.name }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Measures/' + measure.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Edit'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='openDeleteModal(measure)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Delete'"></span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <DeleteModal ref="deleteModal" @confirm="deleteMeasure" />
  </div>
</template>

<script>
import DeleteModal from '@/components/DeleteModal.vue'

export default {
  name: 'Measures',
  components: {
    DeleteModal
  },
  data () {
    return {
      measures: [],
      itemToDelete: null
    }
  },
  created () {
    this.getMeasures()
  },
  methods: {
    openDeleteModal (measure) {
      this.itemToDelete = measure
      this.$refs.deleteModal.openModal()
    },
    deleteMeasure () {
      if (!this.itemToDelete) {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.NoItemSelectedToDelete')
        })
        return
      }

      this.$store
        .dispatch('deleteMeasure', this.itemToDelete.id)
        .then(() => {
          this.measures = this.$store.getters['GET_MEASURES']()
          this.$emit('notify', {
            color: 'green',
            text: this.$t('Measures.MeasureDeleted')
          })
        })
        .catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
        .finally(() => {
          this.$refs.deleteModal.closeModal()
          this.itemToDelete = null
        })
    },
    getMeasures () {
      this.$store.dispatch('getAllMeasures').then(() => {
        this.measures = this.$store.getters['GET_MEASURES']()
      })
    },
    modifyMeasure (measure) {
      this.$store.dispatch('editMeasure', measure).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
